import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.flat-map.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import { defineComponent } from 'vue';
import { onboardingStore, createR4cPayload, changeInfo } from '@/state/onboardingStore';
import { dzrOrgUnitResolve } from '../../../base';
import { isProd, isDev, tzFilter } from '../../../common-ui';
import { FEATURES, R4CAPP } from '../../../types';
import { StepId } from '@/state/tasks';
import GutscheinInfo from './GutscheinInfo.vue';
import CheckEditButton from './CheckEditButton.vue';
import { chain, filter, find } from 'lodash';
import { paketeR4c } from '@/state/pakete';
export default defineComponent({
  components: {
    GutscheinInfo,
    CheckEditButton
  },
  filters: {
    date: tzFilter.filters.tz
  },
  data: () => ({
    R4CAPP,
    FEATURES,
    StepId,
    onboardingStore,
    fullState: onboardingStore.state,
    kommentar: null,
    aktionscode: null,
    aktionscodeInfo: null,
    dzrOrgUnitResolve: dzrOrgUnitResolve,
    isDev,
    isProd
  }),
  computed: {
    paketeR4cToShow() {
      return filter(paketeR4c, app => !isProd || app.live);
    },
    changeInfo() {
      const ci = changeInfo();
      console.log('changeinfo', ci);
      return ci;
    },
    additionalRzLicenceRequired() {
      const currenLicences = chain(onboardingStore.state.activeRechenzentren).map(rz => rz.rzkey).uniq().size().value();
      // remove rzs that are going to be deleted
      const newState = onboardingStore.state.activeRechenzentren.filter(rz => !onboardingStore.state.rechenzentrenToDelete.find(rzToDelete => rz.id === rzToDelete.id));
      // add new rz
      Object.keys(onboardingStore.state.rechenzentrenData).forEach(rzKey => {
        if (onboardingStore.state.rechenzentrenData[rzKey]) {
          newState.push({
            rzkey: rzKey
          });
        }
      });
      const requiredLicenceCount = chain(newState).map(rz => rz.rzkey).uniq().size().value();
      return requiredLicenceCount > currenLicences && requiredLicenceCount > 1;
    },
    flowContainsPurchase() {
      return this.stepIsInFlow(StepId.paket) || this.stepIsInFlow(StepId.r4cPaketAuswahl) || this.additionalRzLicenceRequired;
    },
    requestPending() {
      return onboardingStore.state.requestPending;
    },
    gesamtPreis() {
      return onboardingStore.getters.gesamtPreis;
    },
    rechenzentrenData() {
      return onboardingStore.state.rechenzentrenData;
    },
    rechenzentrenToDelete() {
      return onboardingStore.state.rechenzentrenToDelete;
    },
    gutscheinInfo() {
      return onboardingStore.state.gutscheinInfo;
    },
    rzGutscheinInfo() {
      return onboardingStore.state.gutscheinInfo.test || onboardingStore.state.gutscheinInfo.demo || onboardingStore.state.gutscheinInfo.bfs || onboardingStore.state.gutscheinInfo.dzr || onboardingStore.state.gutscheinInfo.abz || onboardingStore.state.gutscheinInfo.za || onboardingStore.state.gutscheinInfo.health || onboardingStore.state.gutscheinInfo.pvsdental || onboardingStore.state.gutscheinInfo.pvsreiss || onboardingStore.state.gutscheinInfo.arc || onboardingStore.state.gutscheinInfo.teamfaktor;
    },
    usedRoseAccount() {
      return onboardingStore.getters.usedRoseAccount;
    },
    selectedAtleastOneRz() {
      return chain(onboardingStore.state.rechenzentrenData).values().some(d => !!d).value();
    }
  },
  watch: {
    aktionscode: {
      handler() {
        const solutiocodes = ['sales01', 'sales02', 'sales03', 'sales04', 'sales05', 'sales06', 'sales07', 'sales08', 'coaching01', 'coaching02', 'coaching03', 'coaching04', 'coaching05', 'coaching06', 'coaching07', 'coaching08', 'vid01'];
        if (this.aktionscode && solutiocodes.includes(this.aktionscode)) {
          this.aktionscodeInfo = `solutio Aktionscode erkannt. 1 Monat kostenlose Nutzung aktiviert.`;
        } else {
          this.aktionscodeInfo = null;
        }
      },
      deep: true
    }
  },
  mounted() {
    // view cannot be invalid
    this.$emit('viewValidUpdate', true);
    console.log(`state`, this.fullState);
    console.log('r4cpayload', createR4cPayload(onboardingStore.state));
  },
  methods: {
    buchenClicked() {
      console.log('r4cpayload', createR4cPayload(onboardingStore.state));
      onboardingStore.commit.setKommentar(this.kommentar);
      onboardingStore.commit.setAktionscode(this.aktionscode);
      void onboardingStore.dispatch.buchen();
    },
    stepIsInFlow(stepId) {
      return !!find(onboardingStore.getters.shownStepsFlow, step => step.stepId === stepId);
    },
    obfuscatePassword(pw) {
      return pw.charAt(0) + '*'.repeat(pw.length - 1);
    },
    getChoiceInfo(featureId) {
      const ai = chain(paketeR4c).flatMap(app => app.choices).find(c => c.feature === featureId).value();
      return ai;
    }
  }
});