import Vuex from 'vuex';
import Vue from 'vue';
import LogRocket from 'logrocket';
import { createDirectStore } from 'direct-vuex';
import { metricsApi } from '@/services/data';
import {
  IOnboardingPraxisdaten,
  IOnboardingAdminBenutzer,
  IOnboardingZahlungsdaten,
  IOnboardingTechnischerAnprechpartner,
  IOnboardingLizenzInfo,
  IFactoringPraxisInfo,
  rzkeys,
  IRoseAccount,
  IProfile,
  IFactoringOnboardingStart,
  IGutscheinValidation,
  IRoseClientInfoForOnboarding,
  IFactoringClientRechenzentrumDBBase,
  FEATURES,
  R4CAPP,
  compareFeatures,
} from '../../../types';
import { getStepViewConfig, IStep, OnboardingAppTask, StepId } from '@/state/tasks';
import { find, isEmpty, join, mapValues } from 'lodash';
import { isFeatureAlreadySelectedElseWhere, paketeR4cMap } from '@/state/pakete';
import { logVueReactiveObject } from '@rose/common-ui';
import { formatApiErrorForUser } from '@rose/base';

export function createR4cPayload(state: typeof onboardingStore.state) {
  return {
    factoringFeature: state.factoringFeature,
    documentsFeature: state.documentsFeature,
    anamneseFeature: state.anamneseFeature,
    rechenzentrenData: state.rechenzentrenData,
    praxisDaten: state.praxisDaten,
    adminBenutzer: state.adminBenutzer,
    ansprechpartner: state.technischerAnprechpartner,
    zahlungsdaten: state.zahlungsdaten,
    kommentar: state.kommentar,
    aktionscode: state.aktionscode,
    apikey: state.r4cApiKey || state.roseClientInfo?.authToken,
    secret: state.r4cSecret || state.roseClientInfo?.r4cSecret,
    account: state.roseAccount,
    gutscheinCode: state.lizenzInfo?.gutscheinCode,
  };
}

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  state: () => ({
    stepFlow: [] as IStep[],
    finalApiCall: null as null | OnboardingAppTask['finalApiCall'],
    isR4cOnboarding: false,
    activeStep: null as null | IStep,
    maxStepIndexReached: 0,
    done: false,
    requestPending: false,
    warning: null as string | null,

    // metrics view state
    lizenzInfo: null as IOnboardingLizenzInfo | null,
    praxisDaten: null as IOnboardingPraxisdaten | null,
    adminBenutzer: null as IOnboardingAdminBenutzer | null,
    technischerAnprechpartner: null as IOnboardingTechnischerAnprechpartner | null,
    zahlungsdaten: null as IOnboardingZahlungsdaten | null,
    kommentar: null as string | null,
    aktionscode: null as string | null,
    roseAccount: null as IRoseAccount | null,

    // rose user info
    roseClientInfo: null as null | IRoseClientInfoForOnboarding,
    roseProfile: null as null | IProfile,

    // r4c view state
    charlyPraxisInfo: null as IFactoringPraxisInfo | null,
    r4cApiKey: null as string | null,
    r4cSecret: null as string | null,
    showTestRz: false,
    factoringFeature: null as null | FEATURES,
    documentsFeature: null as null | FEATURES,
    anamneseFeature: null as null | FEATURES,
    rechenzentrenData: {
      bfs: null,
      za: null,
      dzr: null,
      abz: null,
      health: null,
      arc: null,
      teamfaktor: null,
      pvsdental: null,
      carecapital: null,
      mediserv: null,
      zab: null,
      test: null,
    } as Record<rzkeys, any>,
    rechenzentrenToDelete: [] as IFactoringClientRechenzentrumDBBase[],
    activeRechenzentren: [] as IFactoringClientRechenzentrumDBBase[],
    gutscheinInfo: {} as Record<rzkeys, { msg: string; type: string; info?: IGutscheinValidation } | undefined>,
  }),
  getters: {
    activeStep(state) {
      return state.activeStep;
    },
    gesamtPreis(state) {
      const lizenzInfo = state.lizenzInfo;
      if (!lizenzInfo || !lizenzInfo.paket) {
        return 0;
      }

      const lizenzen = Math.ceil(lizenzInfo.behandlerCount + lizenzInfo.prophylaxeCount * 0.5);
      const zusatz = lizenzen - lizenzInfo.paket.min;
      return lizenzInfo.paket.preis + zusatz * lizenzInfo.paket.zusatzLizenzPreis;
    },
    shownStepsFlow(state) {
      return state.stepFlow;
    },
    allStepsComplete(state) {
      let requiredSteps = state.stepFlow.filter(step => ![StepId.check, StepId.fertig].includes(step.stepId));
      requiredSteps = requiredSteps.filter(step => !step.skipped);
      return !requiredSteps.find(step => !step.complete);
    },
    usedRoseAccount(state) {
      return !!state.roseAccount;
    },
    activeRechenzentren(state) {
      return state.activeRechenzentren;
    },
    activeFactoringFeature(): FEATURES | undefined {
      return find(store.getters.roseClientActivatedFeatures, f =>
        [FEATURES.FACTORING, FEATURES.FACTORINGEWE].includes(f),
      );
    },
    activeDocumentsFeature(): FEATURES | undefined {
      return find(store.getters.roseClientActivatedFeatures, f =>
        [FEATURES.DOCUMENTS_SMALL, FEATURES.DOCUMENTS_BIG, FEATURES.DOCUMENTS_FLAT].includes(f),
      );
    },
    activeAnamneseFeature(): FEATURES | undefined {
      return find(store.getters.roseClientActivatedFeatures, f =>
        [FEATURES.ANAMNESE_WHITE, FEATURES.ANAMNESE_RED, FEATURES.ANAMNESE_BLACK, FEATURES.ANAMNESE_DIAMOND].includes(
          f,
        ),
      );
    },
    factoringActive(): boolean {
      return store.getters.factoringBasicActive || store.getters.factoringEweActive;
    },
    factoringBasicActive(): boolean {
      return store.getters.roseClientActivatedFeatures.includes(FEATURES.FACTORING);
    },
    factoringEweActive(): boolean {
      return store.getters.roseClientActivatedFeatures.includes(FEATURES.FACTORINGEWE);
    },
    documentsActive(): boolean {
      return (
        store.getters.documentsSmallActive || store.getters.documentsBigActive || store.getters.documentsFlatActive
      );
    },
    documentsSmallActive(): boolean {
      return store.getters.roseClientActivatedFeatures.includes(FEATURES.DOCUMENTS_SMALL);
    },
    documentsBigActive(): boolean {
      return store.getters.roseClientActivatedFeatures.includes(FEATURES.DOCUMENTS_BIG);
    },
    documentsFlatActive(): boolean {
      return store.getters.roseClientActivatedFeatures.includes(FEATURES.DOCUMENTS_FLAT);
    },
    anamneseActive(): boolean {
      return (
        store.getters.anamneseWhiteActive ||
        store.getters.anamneseRedActive ||
        store.getters.anamneseBlackActive ||
        store.getters.anamneseDiamondActive
      );
    },
    anamneseWhiteActive(): boolean {
      return store.getters.roseClientActivatedFeatures.includes(FEATURES.ANAMNESE_WHITE);
    },
    anamneseRedActive(): boolean {
      return store.getters.roseClientActivatedFeatures.includes(FEATURES.ANAMNESE_RED);
    },
    anamneseBlackActive(): boolean {
      return store.getters.roseClientActivatedFeatures.includes(FEATURES.ANAMNESE_BLACK);
    },
    anamneseDiamondActive(): boolean {
      return store.getters.roseClientActivatedFeatures.includes(FEATURES.ANAMNESE_DIAMOND);
    },
    roseClientActivatedFeatures(state): FEATURES[] {
      return state.roseProfile?.lizenzen || [];
    },
  },
  mutations: {
    nextStep(state) {
      const currentIndex = state.stepFlow.indexOf(state.activeStep!);
      state.activeStep = state.stepFlow[currentIndex + 1];
      state.maxStepIndexReached = Math.max(state.maxStepIndexReached, currentIndex + 1);
      if (state.activeStep.skipped) {
        onboardingStore.commit.nextStep();
      }
      console.log('onboardingStore.state', onboardingStore.state);
    },

    prevStep(state) {
      const currentIndex = state.stepFlow.indexOf(state.activeStep!);
      state.activeStep = state.stepFlow[currentIndex - 1];
    },

    setStepComplete(state, { step, complete }: { step: IStep; complete: boolean }) {
      step.complete = complete;
    },

    setSkipStep(state, { step, skip }: { step: IStep; skip: boolean }) {
      step.skipped = skip;
    },

    setSkipStepByStepId(state, { stepId, skip }: { stepId: StepId; skip: boolean }) {
      const step = state.stepFlow.find(entry => entry.stepId === stepId);
      if (step) {
        step.skipped = skip;
      }
    },

    setActiveStep(state, stepId: StepId) {
      const stepOrder = state.stepFlow;
      console.log('setActiveStep', stepId);
      const idx = stepOrder.findIndex(step => step.stepId === stepId);
      state.activeStep = state.stepFlow[idx];
      state.maxStepIndexReached = Math.max(state.maxStepIndexReached, idx);
    },

    initializeAppTask(state, task: OnboardingAppTask) {
      state.stepFlow = task.stepFlow.map(entry => {
        const viewConfig = getStepViewConfig(entry.stepId);
        return {
          ...entry,
          ...viewConfig,
          complete: false,
          skipped: false,
        };
      });
      state.activeStep = state.stepFlow[0];
      state.finalApiCall = task.finalApiCall;
      state.isR4cOnboarding = !!task.isR4cOnboarding;

      console.log('start flow', task);
    },

    setRoseClientInfo(state, info: IRoseClientInfoForOnboarding) {
      LogRocket.identify(info.cid);

      state.roseClientInfo = info;
    },

    setRoseProfile(state, profile: IProfile) {
      LogRocket.identify(profile.cid, {
        praxisKurzName: profile.praxisKurzName,
      });

      state.roseProfile = profile;
    },

    // STEP SPECIFIC DATA
    setFactoringFeature(state, feature: FEATURES) {
      state.factoringFeature = feature;
    },

    setDocumentFeature(state, documentsFeature: FEATURES) {
      state.documentsFeature = documentsFeature;
    },

    setAnamneseFeature(state, anamnese: FEATURES) {
      state.anamneseFeature = anamnese;
    },

    setR4cRechenzentrenToDelete(state, rechenzentrenToDelete: IFactoringClientRechenzentrumDBBase[]) {
      state.rechenzentrenToDelete = rechenzentrenToDelete;
    },

    setActiveRechenzentren(state, activeRechenzentren: IFactoringClientRechenzentrumDBBase[]) {
      state.activeRechenzentren = activeRechenzentren;
    },

    setR4cRechenzentrenData(state, rechenzentrenData: any) {
      state.rechenzentrenData = {
        bfs: rechenzentrenData.bfs ? { ...rechenzentrenData.bfs } : null,
        za: rechenzentrenData.za ? { ...rechenzentrenData.za } : null,
        dzr: rechenzentrenData.dzr ? { ...rechenzentrenData.dzr } : null,
        abz: rechenzentrenData.abz ? { ...rechenzentrenData.abz } : null,
        health: rechenzentrenData.health ? { ...rechenzentrenData.health } : null,
        pvsdental: rechenzentrenData.pvsdental ? { ...rechenzentrenData.pvsdental } : null,
        pvsreiss: rechenzentrenData.pvsreiss ? { ...rechenzentrenData.pvsreiss } : null,
        arc: rechenzentrenData.arc ? { ...rechenzentrenData.arc } : null,
        teamfaktor: rechenzentrenData.teamfaktor ? { ...rechenzentrenData.teamfaktor } : null,
        carecapital: rechenzentrenData.carecapital ? { ...rechenzentrenData.carecapital } : null,
        mediserv: rechenzentrenData.mediserv ? { ...rechenzentrenData.mediserv } : null,
        zab: rechenzentrenData.zab ? { ...rechenzentrenData.zab } : null,
        nelly: rechenzentrenData.nelly ? { ...rechenzentrenData.nelly } : null,
        fabius: rechenzentrenData.fabius ? { ...rechenzentrenData.fabius } : null,
        test: rechenzentrenData.test ? { ...rechenzentrenData.test } : null,
        demo: rechenzentrenData.demo ? { ...rechenzentrenData.demo } : null,
      };
    },

    setR4cGutscheinInfo(state, gutscheinInfo: any) {
      state.gutscheinInfo = gutscheinInfo;
    },

    setR4cSecret(state, secret: string) {
      state.r4cSecret = secret;
    },

    setLizenzInfo(state, info: Partial<IOnboardingLizenzInfo>) {
      if (state.lizenzInfo) {
        state.lizenzInfo = { ...state.lizenzInfo, ...info };
      } else {
        state.lizenzInfo = { ...info } as IOnboardingLizenzInfo;
      }
    },

    hydrateR4cOnboardingWithCharlyInfo(state, info: IFactoringOnboardingStart) {
      console.log('hydrate with', info);
      LogRocket.identify(info.apikey, {
        name: `${info.praxisData?.stempelname1} ${info.praxisData?.stempelname2} ${info.praxisData?.license}`,
      });

      const requiredProps = ['apikey', 'secret'];
      const missingProps: string[] = [];
      for (const key of requiredProps) {
        if (!(key in info)) {
          missingProps.push(key);
        }
      }
      if (!isEmpty(missingProps)) {
        throw new Error('Benötigte Daten wurden nicht übergeben (' + join(missingProps) + ')');
      }

      // extra check for praxisdata
      if (!info.praxisData) {
        state.warning =
          'Die Praxisdaten wurden nicht übergeben. Deshalb können einige Felder nicht vorausgefüllt werden.';
      }

      state.charlyPraxisInfo = info.praxisData;
      state.r4cApiKey = info.apikey;
      state.r4cSecret = info.secret;
      state.showTestRz = info.showTestRz || false;
    },

    buchenStarted(state) {
      state.requestPending = true;
    },

    buchenSucceeded(state) {
      state.requestPending = false;
      state.done = true;
      store.commit.setActiveStep(StepId.fertig);
    },

    buchenFailed(state, message: string) {
      alert(`Die Anfrage ist gescheitert: ${message}`);
      state.requestPending = false;
    },

    setPraxisdaten(state, praxisDaten: IOnboardingPraxisdaten) {
      logVueReactiveObject('setPraxisdaten', praxisDaten);
      const pd = {
        praxis: { ...praxisDaten.praxis },
        auftraggeber: { ...praxisDaten.auftraggeber },
        empfehlung: praxisDaten.empfehlung,
      };
      if (!pd.praxis.plz && pd.auftraggeber.plz) {
        pd.praxis.plz = pd.auftraggeber.plz;
      }
      if (!pd.praxis.stadt && pd.auftraggeber.stadt) {
        pd.praxis.stadt = pd.auftraggeber.stadt;
      }
      if (!pd.praxis.straße && pd.auftraggeber.straße) {
        pd.praxis.straße = pd.auftraggeber.straße;
      }
      pd.praxis.licence = state.charlyPraxisInfo?.license;
      state.praxisDaten = pd;
    },

    setWarning(state, msg: string) {
      state.warning = msg;
    },

    setAdminBenutzer(state, adminBenutzer: IOnboardingAdminBenutzer) {
      state.adminBenutzer = { ...adminBenutzer };
    },

    setTechnischerAnprechpartner(state, technischerAnprechpartner: IOnboardingTechnischerAnprechpartner) {
      state.technischerAnprechpartner = { ...technischerAnprechpartner };
    },

    setZahlungsdaten(state, zahlungsdaten: IOnboardingZahlungsdaten) {
      state.zahlungsdaten = { ...zahlungsdaten };
    },

    setKommentar(state, kommentar: string | null) {
      state.kommentar = kommentar;
    },

    setAktionscode(state, aktionscode: string | null) {
      state.aktionscode = aktionscode;
    },

    setRoseAccount(state, roseAccount: IRoseAccount | null) {
      state.roseAccount = roseAccount;
    },

    restoreFromLocalStorage(state) {
      try {
        const storedState: any = JSON.parse(localStorage.getItem(localStorageKey) || 'null');
        if (storedState) {
          // fixup component ref in stepFlow & activeStep
          if (storedState.stepFlow) {
            storedState.stepFlow.forEach((s: IStep) => {
              s.component = getStepViewConfig(s.stepId).component;
            });
          }
          if (storedState.activeStep) {
            storedState.activeStep.component = getStepViewConfig(storedState.activeStep.stepId).component;
          }

          for (const key in storedState) {
            if (Object.prototype.hasOwnProperty.call(storedState, key)) {
              (state as any)[key] = storedState[key];
            }
          }
        }
      } catch (ex) {
        console.warn(ex);
      }
    },
  },
  actions: {
    async createOnboardingAuthSession(context, info: IRoseClientInfoForOnboarding) {
      const { state, dispatch, commit } = rootActionContext(context);

      // needed to use initial token
      commit.setRoseClientInfo(info);

      // if the authToken is a token (not an apikey) we want to create a longer living token
      if (!info.isApikey) {
        const midSessionToken = (await metricsApi.auth.createMidSessionToken()).token;

        // set info with special longer living token
        commit.setRoseClientInfo({
          ...info,
          authToken: midSessionToken,
        });
      }
    },
    async buchen(context) {
      // Actions instance has 'state', 'getters', 'commit' and 'dispatch' properties
      const { state, commit } = rootActionContext(context);

      try {
        commit.buchenStarted();
        await state.finalApiCall!(state);
        commit.buchenSucceeded();
      } catch (e) {
        const err = e;
        console.log('buchenFailed', err);
        commit.buchenFailed(formatApiErrorForUser(e));
      }
    },
  },
});

const localStorageKey = 'rose_onboarding.v1.state';
// Subscribe to store updates
store.original.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem(localStorageKey, JSON.stringify(state));
});

// not active right now
// store.commit.restoreFromLocalStorage();
// window.restore = (state: any) => {
//   localStorage.setItem(localStorageKey, JSON.stringify(state));
//   store.commit.restoreFromLocalStorage();
// };

export const onboardingStore = store;

// this knows if the client already has those apps active
export function hasApp(app: any) {
  if (app === R4CAPP.FACTORING && onboardingStore.getters.factoringActive) {
    return true;
  }
  if (app === R4CAPP.DOCUMENTS && onboardingStore.getters.documentsActive) {
    return true;
  }
  if (app === R4CAPP.ANAMNESE && onboardingStore.getters.anamneseActive) {
    return true;
  }

  return false;
}

// this knows if the client already has those features active
// eslint-disable-next-line complexity
export function hasAppFeature(app: any, feature: FEATURES) {
  if (app === R4CAPP.FACTORING) {
    if (feature === FEATURES.FACTORING && onboardingStore.getters.factoringBasicActive) {
      return true;
    }
    if (feature === FEATURES.FACTORINGEWE && onboardingStore.getters.factoringEweActive) {
      return true;
    }
    return false;
  }
  if (app === R4CAPP.DOCUMENTS) {
    if (feature === FEATURES.DOCUMENTS_SMALL && onboardingStore.getters.documentsSmallActive) {
      return true;
    }
    if (feature === FEATURES.DOCUMENTS_BIG && onboardingStore.getters.documentsBigActive) {
      return true;
    }
    if (feature === FEATURES.DOCUMENTS_FLAT && onboardingStore.getters.documentsFlatActive) {
      return true;
    }

    return false;
  }
  if (app === R4CAPP.ANAMNESE) {
    if (feature === FEATURES.ANAMNESE_WHITE && onboardingStore.getters.anamneseWhiteActive) {
      return true;
    }
    if (feature === FEATURES.ANAMNESE_RED && onboardingStore.getters.anamneseRedActive) {
      return true;
    }
    if (feature === FEATURES.ANAMNESE_BLACK && onboardingStore.getters.anamneseBlackActive) {
      return true;
    }
    if (feature === FEATURES.ANAMNESE_DIAMOND && onboardingStore.getters.anamneseDiamondActive) {
      return true;
    }
    return false;
  }
  return false;
}

export interface IOnboardingChangeType {
  alt: FEATURES | undefined;
  neu: FEATURES | undefined;
  typ?: 'cancel' | 'unchanged' | 'upgrade' | 'downgrade' | 'included';
  includeInfo?: { where: string[]; whereNames: string[] };
}

export function featureStates(): {
  [key: string]: IOnboardingChangeType;
} {
  return {
    [R4CAPP.FACTORING]: {
      alt: onboardingStore.getters.activeFactoringFeature,
      neu: onboardingStore.state.factoringFeature === null ? undefined : onboardingStore.state.factoringFeature,
    },
    [R4CAPP.DOCUMENTS]: {
      alt: onboardingStore.getters.activeDocumentsFeature,
      neu: onboardingStore.state.documentsFeature === null ? undefined : onboardingStore.state.documentsFeature,
    },
    [R4CAPP.ANAMNESE]: {
      alt: onboardingStore.getters.activeAnamneseFeature,
      neu: onboardingStore.state.anamneseFeature === null ? undefined : onboardingStore.state.anamneseFeature,
    },
  };
}

export function changeType(appId: string): IOnboardingChangeType {
  const fs = featureStates();
  const s = fs[appId];
  const app = paketeR4cMap[appId];
  for (const choice of app.choices) {
    const ifse = isFeatureAlreadySelectedElseWhere(
      appId,
      choice.feature,
      mapValues(fs, x => x.neu || FEATURES.NONE),
    );
    console.log('ifse', choice.feature, ifse);
    if (ifse.included) {
      s.typ = 'included';
      s.neu = choice.feature;
      s.includeInfo = { where: ifse.where, whereNames: ifse.whereNames };
      return s;
    }
  }
  if (s.alt === s.neu || (s.alt === undefined && s.neu === FEATURES.NONE)) {
    s.typ = 'unchanged';
    return s;
  }
  if (s.neu === undefined || s.neu === FEATURES.NONE) {
    // remove current feature
    s.typ = 'cancel';
    return s;
  }
  if (compareFeatures(s.alt, s.neu) > 0) {
    s.typ = 'upgrade';
    return s;
  }
  s.typ = 'downgrade';
  return s;
}

export function changeInfo() {
  return {
    [R4CAPP.FACTORING]: changeType(R4CAPP.FACTORING),
    [R4CAPP.DOCUMENTS]: changeType(R4CAPP.DOCUMENTS),
    [R4CAPP.ANAMNESE]: changeType(R4CAPP.ANAMNESE),
  };
}
