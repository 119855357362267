import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c(VCardText, [_c(VAlert, {
    staticClass: "mt-2 mb-2",
    attrs: {
      "dense": "",
      "type": "info"
    }
  }, [_c('p', [_vm._v("Mit rose4charly-Factoring kannst du digital die Einwilligungserklärung aufnehmen und direkt aus dem charly Bonitätsabfragen starten. So hast du immer den Überblick wie der Stand der Bonitätsprüfungen ist und kannst die Ergebnisse direkt in deinem charly sehen.")]), _c('p', [_vm._v("Du kannst rose4charly-Factoring 4 Wochen lang kostenfrei testen.")])]), _vm.activeRechenzentren.length ? [_c('h3', {
    staticClass: "mb-4"
  }, [_vm._v("Aktive Rechenzentren entfernen")]), _c('div', {
    staticClass: "rechenzentren mb-8"
  }, _vm._l(_vm.activeRechenzentren, function (activeRz) {
    return _c('div', {
      staticClass: "rechenzentrum",
      class: {
        shouldDelete: activeRz.shouldDelete
      },
      on: {
        "click": function ($event) {
          return _vm.toogleMarkForDeletion(activeRz);
        }
      }
    }, [_c(VIcon, {
      staticClass: "rzIcon"
    }, [_vm._v("mdi-delete")]), activeRz.shouldDelete ? _c('div', {
      staticClass: "deleteNotice"
    }, [_vm._v("Entfernen")]) : _vm._e(), _vm.getRzIconUrl(activeRz.rzkey) ? _c('img', {
      class: activeRz.rzkey + "-logo",
      attrs: {
        "src": _vm.getRzIconUrl(activeRz.rzkey)
      }
    }) : _c('div', {
      staticClass: "fallbacktext-logo"
    }, [_vm._v(_vm._s(activeRz.rzkey))]), _c('div', {
      staticClass: "vertrags-info"
    }, [_vm._v("Vertragsinfo: " + _vm._s(activeRz.vertragsInfo))]), _c('div', {
      staticClass: "vertrags-info"
    }, [_vm._v("Hinzugefügt am " + _vm._s(_vm._f("date")(activeRz.createdAt)))])], 1);
  }), 0), _c('br')] : _vm._e(), !_vm.isAdditionalRz ? [_c('h3', [_vm._v("Wählen Sie Ihr primäres Rechenzentrum")]), _c('div', {
    staticClass: "mb-4"
  }, [_c('small', [_vm._v("Sie können später weitere Rechenzentren hinzufügen")])])] : [_c('h3', [_vm._v("Fügen Sie ein Rechenzentrum hinzu")]), _c(VAlert, {
    staticClass: "mt-2 mb-2",
    attrs: {
      "dense": "",
      "type": "info"
    }
  }, [_vm._v("Jedes weitere Rechenzentrum (eines anderen Anbieters) benötigt eine Zusatzlizenz zu Kosten von 30€ pro Monat")])], _c('div', {
    staticClass: "rechenzentren"
  }, [_c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.bfs
    },
    attrs: {
      "data-testid": "activate-bfs"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("bfs");
      }
    }
  }, [_c('img', {
    staticClass: "bfs-logo",
    attrs: {
      "src": require("../assets/bfs.png")
    }
  }), _vm.rzSelected.bfs ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.za
    },
    attrs: {
      "data-testid": "activate-za"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("za");
      }
    }
  }, [_c('img', {
    staticClass: "za-logo",
    attrs: {
      "src": require("../assets/za.png")
    }
  }), _vm.rzSelected.za ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.dzr
    },
    attrs: {
      "data-testid": "activate-dzr"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("dzr");
      }
    }
  }, [_c('img', {
    staticClass: "dzr-logo",
    attrs: {
      "src": require("../assets/dzr.png")
    }
  }), _vm.rzSelected.dzr ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.abz
    },
    attrs: {
      "data-testid": "activate-abz"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("abz");
      }
    }
  }, [_c('img', {
    staticClass: "abz-logo",
    attrs: {
      "src": require("../assets/abz.png")
    }
  }), _vm.rzSelected.abz ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.health
    },
    attrs: {
      "data-testid": "activate-health"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("health");
      }
    }
  }, [_c('img', {
    staticClass: "health-logo",
    attrs: {
      "src": require("../assets/health.png")
    }
  }), _vm.rzSelected.health ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.pvsreiss
    },
    attrs: {
      "data-testid": "activate-pvsreiss"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("pvsreiss");
      }
    }
  }, [_c('img', {
    staticClass: "pvsreiss-logo",
    attrs: {
      "src": require("../assets/pvsreiss.png")
    }
  }), _vm.rzSelected.pvsreiss ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.arc
    },
    attrs: {
      "data-testid": "activate-arc"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("arc");
      }
    }
  }, [_c('img', {
    staticClass: "arc-logo",
    attrs: {
      "src": require("../assets/arc.png")
    }
  }), _vm.rzSelected.arc ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.teamfaktor
    },
    attrs: {
      "data-testid": "activate-teamfaktor"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("teamfaktor");
      }
    }
  }, [_c('img', {
    staticClass: "teemfactor-logo",
    attrs: {
      "src": require("../assets/teemfactor.png")
    }
  }), _vm.rzSelected.teamfaktor ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _vm.showPvsDentalRz ? _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.pvsdental
    },
    attrs: {
      "data-testid": "activate-pvsdental"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("pvsdental");
      }
    }
  }, [_c('img', {
    staticClass: "pvsdental-logo",
    attrs: {
      "src": require("../assets/pvsdental.png")
    }
  }), _vm.rzSelected.pvsdental ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.carecapital
    },
    attrs: {
      "data-testid": "activate-carecapital"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("carecapital");
      }
    }
  }, [_c('img', {
    staticClass: "carecapital-logo",
    attrs: {
      "src": require("../assets/carecapital.png")
    }
  }), _vm.rzSelected.carecapital ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.mediserv
    },
    attrs: {
      "data-testid": "activate-mediserv"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("mediserv");
      }
    }
  }, [_c('img', {
    staticClass: "mediserv-logo",
    attrs: {
      "src": require("../assets/mediserv.png")
    }
  }), _vm.rzSelected.mediserv ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.zab
    },
    attrs: {
      "data-testid": "activate-zab"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("zab");
      }
    }
  }, [_c('img', {
    staticClass: "zab-logo",
    attrs: {
      "src": require("../assets/zab.png")
    }
  }), _vm.rzSelected.zab ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.nelly
    },
    attrs: {
      "data-testid": "activate-nelly"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("nelly");
      }
    }
  }, [_c('img', {
    staticClass: "nelly-logo",
    attrs: {
      "src": require("../assets/nelly.png")
    }
  }), _vm.rzSelected.nelly ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.fabius
    },
    attrs: {
      "data-testid": "activate-fabius"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("fabius");
      }
    }
  }, [_c('img', {
    staticClass: "fabius-logo",
    attrs: {
      "src": require("../assets/fabius.png")
    }
  }), _vm.rzSelected.fabius ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]), _vm.showTestRz ? _c('div', {
    staticClass: "rechenzentrum",
    class: {
      active: _vm.rzSelected.test
    },
    attrs: {
      "data-testid": "activate-test"
    },
    on: {
      "click": function ($event) {
        return _vm.selectRechenzentrum("test");
      }
    }
  }, [_c('img', {
    staticClass: "test-logo",
    attrs: {
      "src": require("../assets/rose.png")
    }
  }), _c('div', {
    staticClass: "test-text"
  }, [_vm._v("Test")]), _vm.rzSelected.test ? _c('span', [_vm._v("Aktiviert")]) : _vm._e()]) : _vm._e()]), _c(VForm, {
    model: {
      value: _vm.formValid,
      callback: function ($$v) {
        _vm.formValid = $$v;
      },
      expression: "formValid"
    }
  }, [_vm.rzSelected.bfs ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("BFS")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_vm.canReadClipboard ? _c('div', {
    staticClass: "field"
  }, [_c(VTooltip, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VBtn, _vm._g(_vm._b({
          staticClass: "get-clipboard",
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.readCredentialsFromClipboard();
            }
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Daten aus der Zwischenablage übernehmen")])];
      }
    }], null, false, 47725397)
  }, [_c('span', [_vm._v("Diese Daten können im BFS-Mandantenportal kopiert werden")])])], 1) : _c('div', {
    staticClass: "field"
  }, [_c(VTextField, {
    attrs: {
      "label": "Daten aus dem BFS-Mandantenportal hier einfügen",
      "type": "password",
      "append-icon": _vm.showc ? "mdi-eye" : "mdi-eye-off",
      "type": _vm.showc ? "text" : "password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showc = !_vm.showc;
      }
    },
    model: {
      value: _vm.inputCredentials,
      callback: function ($$v) {
        _vm.inputCredentials = $$v;
      },
      expression: "inputCredentials"
    }
  }), _c(VBtn, {
    staticClass: "fill-values",
    attrs: {
      "color": "primary",
      "disabled": !_vm.inputCredentials
    },
    on: {
      "click": function ($event) {
        return _vm.readCredentialsFromInput();
      }
    }
  }, [_vm._v("Lesen")])], 1), _c('div', {
    staticClass: "field"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Benutzer",
      "required": ""
    },
    model: {
      value: _vm.bfsDaten.benutzer,
      callback: function ($$v) {
        _vm.$set(_vm.bfsDaten, "benutzer", $$v);
      },
      expression: "bfsDaten.benutzer"
    }
  }), _vm.bfsCredentialsValidationStatus === true ? _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "color": "success"
    }
  }, [_vm._v("mdi-check")]) : _vm._e(), _vm.bfsCredentialsValidationStatus === false ? _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-alert")]) : _vm._e()], 1), _c('div', {
    staticClass: "field"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Passwort",
      "required": "",
      "type": "password",
      "append-icon": _vm.showpw ? "mdi-eye" : "mdi-eye-off",
      "type": _vm.showpw ? "text" : "password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showpw = !_vm.showpw;
      }
    },
    model: {
      value: _vm.bfsDaten.passwort,
      callback: function ($$v) {
        _vm.$set(_vm.bfsDaten, "passwort", $$v);
      },
      expression: "bfsDaten.passwort"
    }
  }), _vm.bfsCredentialsValidationStatus === true ? _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "color": "success"
    }
  }, [_vm._v("mdi-check")]) : _vm._e(), _vm.bfsCredentialsValidationStatus === false ? _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-alert")]) : _vm._e()], 1), _c('div', {
    staticClass: "field"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Zertifikat-Token",
      "required": ""
    },
    model: {
      value: _vm.bfsDaten.zertifikattoken,
      callback: function ($$v) {
        _vm.$set(_vm.bfsDaten, "zertifikattoken", $$v);
      },
      expression: "bfsDaten.zertifikattoken"
    }
  }), _vm.bfsCredentialsValidationStatus === true ? _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "color": "success"
    }
  }, [_vm._v("mdi-check")]) : _vm._e(), _vm.bfsCredentialsValidationStatus === false ? _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-alert")]) : _vm._e()], 1), _c('div', {
    staticClass: "field"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Zertifikat-Passwort",
      "required": "",
      "type": "password",
      "append-icon": _vm.showzpw ? "mdi-eye" : "mdi-eye-off",
      "type": _vm.showzpw ? "text" : "password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showzpw = !_vm.showzpw;
      }
    },
    model: {
      value: _vm.bfsDaten.zertifikatpasswort,
      callback: function ($$v) {
        _vm.$set(_vm.bfsDaten, "zertifikatpasswort", $$v);
      },
      expression: "bfsDaten.zertifikatpasswort"
    }
  }), _vm.bfsCertPasswordValidationStatus === true ? _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "color": "success"
    }
  }, [_vm._v("mdi-check")]) : _vm._e(), _vm.bfsCertPasswordValidationStatus === false ? _c(VIcon, {
    staticClass: "ml-2",
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-alert")]) : _vm._e()], 1)])]) : _vm._e(), _vm.rzSelected.za ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("ZA")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Praxisnummer",
      "type": "customerNumber",
      "required": ""
    },
    model: {
      value: _vm.zaDaten.customerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.zaDaten, "customerNumber", $$v);
      },
      expression: "zaDaten.customerNumber"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Benutzername",
      "required": ""
    },
    model: {
      value: _vm.zaDaten.username,
      callback: function ($$v) {
        _vm.$set(_vm.zaDaten, "username", $$v);
      },
      expression: "zaDaten.username"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Passwort",
      "type": "password",
      "required": "",
      "append-icon": _vm.showzapw ? "mdi-eye" : "mdi-eye-off",
      "type": _vm.showzapw ? "text" : "password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showzapw = !_vm.showzapw;
      }
    },
    model: {
      value: _vm.zaDaten.password,
      callback: function ($$v) {
        _vm.$set(_vm.zaDaten, "password", $$v);
      },
      expression: "zaDaten.password"
    }
  }), _c('div', {
    staticClass: "gutschein"
  }, [_c(VTextField, {
    attrs: {
      "label": "Gutschein vom Factoringanbieter"
    },
    on: {
      "input": function ($event) {
        return _vm.checkGutschein(_vm.zaDaten.gutschein, "za");
      }
    },
    model: {
      value: _vm.zaDaten.gutschein,
      callback: function ($$v) {
        _vm.$set(_vm.zaDaten, "gutschein", $$v);
      },
      expression: "zaDaten.gutschein"
    }
  }), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.za
    }
  })], 1)], 1), !_vm.zaCredentialsValidationStatus && _vm.zaCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("Fehler: " + _vm._s(_vm.zaCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.dzr ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("DZR")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VBtnToggle, {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.dzrDaten.org_unit,
      callback: function ($$v) {
        _vm.$set(_vm.dzrDaten, "org_unit", $$v);
      },
      expression: "dzrDaten.org_unit"
    }
  }, _vm._l(_vm.dzrOrgUnits, function (orgUnit) {
    return _c(VBtn, {
      key: orgUnit.id,
      attrs: {
        "value": orgUnit.id,
        "title": orgUnit.lang
      }
    }, [_vm._v(_vm._s(orgUnit.name))]);
  }), 1), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Kunden-/Vertragsnummer",
      "required": ""
    },
    model: {
      value: _vm.dzrDaten.contract_number,
      callback: function ($$v) {
        _vm.$set(_vm.dzrDaten, "contract_number", $$v);
      },
      expression: "dzrDaten.contract_number"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Benutzer",
      "require": ""
    },
    model: {
      value: _vm.dzrDaten.username,
      callback: function ($$v) {
        _vm.$set(_vm.dzrDaten, "username", $$v);
      },
      expression: "dzrDaten.username"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Passwort",
      "type": "password",
      "required": "",
      "append-icon": _vm.showdzrpw ? "mdi-eye" : "mdi-eye-off",
      "type": _vm.showdzrpw ? "text" : "password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showdzrpw = !_vm.showdzrpw;
      }
    },
    model: {
      value: _vm.dzrDaten.password,
      callback: function ($$v) {
        _vm.$set(_vm.dzrDaten, "password", $$v);
      },
      expression: "dzrDaten.password"
    }
  }), _c('div', {
    staticClass: "gutschein"
  }, [_c(VTextField, {
    attrs: {
      "label": "Gutschein vom Factoringanbieter"
    },
    on: {
      "input": function ($event) {
        return _vm.checkGutschein(_vm.dzrDaten.gutschein, "dzr");
      }
    },
    model: {
      value: _vm.dzrDaten.gutschein,
      callback: function ($$v) {
        _vm.$set(_vm.dzrDaten, "gutschein", $$v);
      },
      expression: "dzrDaten.gutschein"
    }
  }), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.dzr
    }
  })], 1)], 1), !_vm.dzrCredentialsValidationStatus && _vm.dzrCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("Fehler: " + _vm._s(_vm.dzrCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.abz ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("ABZ")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Kunden-/Vertragsnummer",
      "required": ""
    },
    model: {
      value: _vm.abzDaten.contract_number,
      callback: function ($$v) {
        _vm.$set(_vm.abzDaten, "contract_number", $$v);
      },
      expression: "abzDaten.contract_number"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Benutzer",
      "required": ""
    },
    model: {
      value: _vm.abzDaten.username,
      callback: function ($$v) {
        _vm.$set(_vm.abzDaten, "username", $$v);
      },
      expression: "abzDaten.username"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Passwort",
      "type": "password",
      "required": "",
      "append-icon": _vm.showabzpw ? "mdi-eye" : "mdi-eye-off",
      "type": _vm.showabzpw ? "text" : "password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showabzpw = !_vm.showabzpw;
      }
    },
    model: {
      value: _vm.abzDaten.password,
      callback: function ($$v) {
        _vm.$set(_vm.abzDaten, "password", $$v);
      },
      expression: "abzDaten.password"
    }
  }), _c('div', {
    staticClass: "gutschein"
  }, [_c(VTextField, {
    attrs: {
      "label": "Gutschein vom Factoringanbieter"
    },
    on: {
      "input": function ($event) {
        return _vm.checkGutschein(_vm.abzDaten.gutschein, "abz");
      }
    },
    model: {
      value: _vm.abzDaten.gutschein,
      callback: function ($$v) {
        _vm.$set(_vm.abzDaten, "gutschein", $$v);
      },
      expression: "abzDaten.gutschein"
    }
  }), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.abz
    }
  })], 1)], 1), !_vm.abzCredentialsValidationStatus && _vm.abzCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("Fehler: " + _vm._s(_vm.abzCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.health ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("Health AG")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VBtn, {
    staticClass: "kupobutton",
    attrs: {
      "color": "primary",
      "href": "https://kunden.healthag.de/kunde/secure/rose4charlyapikey.htm",
      "target": "_blank"
    }
  }, [_vm._v("Kundenportal öffnen um die Authentifizierungsdaten abzulesen")]), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "ID",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.healthDaten.customerNumber,
      callback: function ($$v) {
        _vm.$set(_vm.healthDaten, "customerNumber", $$v);
      },
      expression: "healthDaten.customerNumber"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Api-Key",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.healthDaten.apiKey,
      callback: function ($$v) {
        _vm.$set(_vm.healthDaten, "apiKey", $$v);
      },
      expression: "healthDaten.apiKey"
    }
  })], 1), !_vm.healthCredentialsValidationStatus && _vm.healthCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("Fehler: " + _vm._s(_vm.healthCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.pvsreiss ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("PVS-Reiss")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Benutzername",
      "required": ""
    },
    model: {
      value: _vm.pvsreissDaten.username,
      callback: function ($$v) {
        _vm.$set(_vm.pvsreissDaten, "username", $$v);
      },
      expression: "pvsreissDaten.username"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Passwort",
      "type": "password",
      "required": "",
      "append-icon": _vm.showpvsreisspw ? "mdi-eye" : "mdi-eye-off",
      "type": _vm.showpvsreisspw ? "text" : "password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showpvsreisspw = !_vm.showpvsreisspw;
      }
    },
    model: {
      value: _vm.pvsreissDaten.password,
      callback: function ($$v) {
        _vm.$set(_vm.pvsreissDaten, "password", $$v);
      },
      expression: "pvsreissDaten.password"
    }
  })], 1), !_vm.pvsreissCredentialsValidationStatus && _vm.pvsreissCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("Fehler: " + _vm._s(_vm.pvsreissCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.arc ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("ARC")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Kundennummer",
      "required": ""
    },
    model: {
      value: _vm.arcDaten.kunde,
      callback: function ($$v) {
        _vm.$set(_vm.arcDaten, "kunde", $$v);
      },
      expression: "arcDaten.kunde"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Hash",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.arcDaten.hash,
      callback: function ($$v) {
        _vm.$set(_vm.arcDaten, "hash", $$v);
      },
      expression: "arcDaten.hash"
    }
  })], 1), !_vm.arcCredentialsValidationStatus && _vm.arcCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("Fehler: " + _vm._s(_vm.arcCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.teamfaktor ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("teemfactor")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Benutzer",
      "required": ""
    },
    model: {
      value: _vm.teamfaktorDaten.benutzer,
      callback: function ($$v) {
        _vm.$set(_vm.teamfaktorDaten, "benutzer", $$v);
      },
      expression: "teamfaktorDaten.benutzer"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Passwort",
      "type": "password",
      "required": "",
      "append-icon": _vm.showteamfaktorpw ? "mdi-eye" : "mdi-eye-off",
      "type": _vm.showteamfaktorpw ? "text" : "password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showteamfaktorpw = !_vm.showteamfaktorpw;
      }
    },
    model: {
      value: _vm.teamfaktorDaten.passwort,
      callback: function ($$v) {
        _vm.$set(_vm.teamfaktorDaten, "passwort", $$v);
      },
      expression: "teamfaktorDaten.passwort"
    }
  })], 1), !_vm.teamfaktorCredentialsValidationStatus && _vm.teamfaktorCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("Fehler: " + _vm._s(_vm.teamfaktorCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.carecapital ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("CareCapital")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Benutzername / Kundennummer",
      "required": ""
    },
    model: {
      value: _vm.careCapitalDaten.username,
      callback: function ($$v) {
        _vm.$set(_vm.careCapitalDaten, "username", $$v);
      },
      expression: "careCapitalDaten.username"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Passwort",
      "required": "",
      "append-icon": _vm.showpvscarecapitalpw ? "mdi-eye" : "mdi-eye-off",
      "type": _vm.showpvscarecapitalpw ? "text" : "password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showpvscarecapitalpw = !_vm.showpvscarecapitalpw;
      }
    },
    model: {
      value: _vm.careCapitalDaten.password,
      callback: function ($$v) {
        _vm.$set(_vm.careCapitalDaten, "password", $$v);
      },
      expression: "careCapitalDaten.password"
    }
  })], 1), !_vm.careCapitalCredentialsValidationStatus && _vm.careCapitalCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("Fehler: " + _vm._s(_vm.careCapitalCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.mediserv ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("mediserv")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Kundennummer",
      "required": ""
    },
    model: {
      value: _vm.mediservDaten.kunde,
      callback: function ($$v) {
        _vm.$set(_vm.mediservDaten, "kunde", $$v);
      },
      expression: "mediservDaten.kunde"
    }
  })], 1), !_vm.mediservCredentialsValidationStatus && _vm.mediservCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(_vm._s(_vm.mediservCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.zab ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("ZAB")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c(VAlert, {
    attrs: {
      "type": "info"
    }
  }, [_c('p', [_vm._v("Für eine Anbindung von rose4charly an die ZAB, logge dich bitte in "), _c('a', {
    staticClass: "unstyledlink",
    attrs: {
      "href": "https://portal01.zab-abrechnung.de/Login",
      "target": "_blank"
    }
  }, [_vm._v("LUNA-Web")]), _vm._v(" ein. Unter Downloads findest du die Nutzungsvereinbarung, welche du ausgefüllt an die ZAB Abrechnungsgesellschaft sendest. Im Anschluss erhältst du deine Zugangsdaten.")])]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Benutzer",
      "required": ""
    },
    model: {
      value: _vm.zabDaten.username,
      callback: function ($$v) {
        _vm.$set(_vm.zabDaten, "username", $$v);
      },
      expression: "zabDaten.username"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "password",
      "label": "Passwort",
      "required": ""
    },
    model: {
      value: _vm.zabDaten.password,
      callback: function ($$v) {
        _vm.$set(_vm.zabDaten, "password", $$v);
      },
      expression: "zabDaten.password"
    }
  })], 1), !_vm.zabCredentialsValidationStatus && _vm.zabCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(_vm._s(_vm.zabCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.nelly ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("nelly")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Benutzer",
      "required": ""
    },
    model: {
      value: _vm.nellyDaten.username,
      callback: function ($$v) {
        _vm.$set(_vm.nellyDaten, "username", $$v);
      },
      expression: "nellyDaten.username"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "password",
      "label": "Passwort",
      "required": ""
    },
    model: {
      value: _vm.nellyDaten.password,
      callback: function ($$v) {
        _vm.$set(_vm.nellyDaten, "password", $$v);
      },
      expression: "nellyDaten.password"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Organisations-ID",
      "required": ""
    },
    model: {
      value: _vm.nellyDaten.organization_id,
      callback: function ($$v) {
        _vm.$set(_vm.nellyDaten, "organization_id", $$v);
      },
      expression: "nellyDaten.organization_id"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Kundennummer",
      "required": ""
    },
    model: {
      value: _vm.nellyDaten.customer_number,
      callback: function ($$v) {
        _vm.$set(_vm.nellyDaten, "customer_number", $$v);
      },
      expression: "nellyDaten.customer_number"
    }
  })], 1), !_vm.nellyCredentialsValidationStatus && _vm.nellyCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(_vm._s(_vm.nellyCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.fabius ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("fabius")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Key",
      "required": ""
    },
    model: {
      value: _vm.fabiusDaten.key,
      callback: function ($$v) {
        _vm.$set(_vm.fabiusDaten, "key", $$v);
      },
      expression: "fabiusDaten.key"
    }
  })], 1), _c('div', {
    staticClass: "gutschein"
  }, [_c(VTextField, {
    attrs: {
      "label": "Gutschein vom Factoringanbieter"
    },
    on: {
      "input": function ($event) {
        return _vm.checkGutschein(_vm.fabiusDaten.gutschein, "fabius");
      }
    },
    model: {
      value: _vm.fabiusDaten.gutschein,
      callback: function ($$v) {
        _vm.$set(_vm.fabiusDaten, "gutschein", $$v);
      },
      expression: "fabiusDaten.gutschein"
    }
  }), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.fabius
    }
  })], 1), !_vm.fabiusCredentialsValidationStatus && _vm.fabiusCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(_vm._s(_vm.fabiusCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.pvsdental ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("PVS-Dental")]), _c('h5', [_vm._v("Öffnen Sie das Kundeportal und geben Sie die Bonitäts-Schnittstelle frei")]), _c('div', {
    staticClass: "fields"
  }, [_vm.pvsdentalWaitForRegistration === "idle" ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "href": _vm.pvsdentalRegistrationUrl,
      "target": "_blank",
      "disabled": !_vm.pvsdentalRegistrationUrl
    },
    on: {
      "click": _vm.startObservingPvsDentalRegistration
    }
  }, [_vm._v("Kundenportal öffnen")]) : _c(VBtn, {
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.pvsdentalWaitForRegistration = "cancel";
      }
    }
  }, [_vm._v("Warten auf Abschluss der Registrierung stoppen")])], 1), !_vm.pvsdentalCredentialsValidationStatus && _vm.pvsdentalCredentialsValidationError ? _c(VAlert, {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("Fehler: " + _vm._s(_vm.pvsdentalCredentialsValidationError))]) : _vm._e()], 1) : _vm._e(), _vm.rzSelected.test ? _c('div', {
    staticClass: "rzDaten"
  }, [_c('h3', [_vm._v("Test")]), _c('h5', [_vm._v("Zugangs-Daten")]), _c('div', {
    staticClass: "fields"
  }, [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Benutzer",
      "required": "",
      "data-testid": "test_user"
    },
    model: {
      value: _vm.testDaten.user,
      callback: function ($$v) {
        _vm.$set(_vm.testDaten, "user", $$v);
      },
      expression: "testDaten.user"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Passwort",
      "type": "password",
      "required": "",
      "data-testid": "test_pass"
    },
    model: {
      value: _vm.testDaten.password,
      callback: function ($$v) {
        _vm.$set(_vm.testDaten, "password", $$v);
      },
      expression: "testDaten.password"
    }
  }), _c('div', {
    staticClass: "gutschein"
  }, [_c(VTextField, {
    attrs: {
      "label": "Gutschein vom Factoringanbieter"
    },
    on: {
      "input": function ($event) {
        return _vm.checkGutschein(_vm.testDaten.gutschein, "test");
      }
    },
    model: {
      value: _vm.testDaten.gutschein,
      callback: function ($$v) {
        _vm.$set(_vm.testDaten, "gutschein", $$v);
      },
      expression: "testDaten.gutschein"
    }
  }), _c('GutscheinInfo', {
    attrs: {
      "gutschein-info": _vm.gutscheinInfo.test
    }
  })], 1)], 1)]) : _vm._e()]), _c('div', {
    staticClass: "done"
  }, [_c('div', [_c(VBtn, {
    staticClass: "float-right",
    attrs: {
      "color": "primary",
      "disabled": !_vm.allowContinue,
      "loading": _vm.checkPending,
      "data-testid": "next"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm.rzSelected.bfs ? _c('span', [_vm._v("Prüfen")]) : _c('span', [_vm._v("Weiter")])])], 1)])], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };